import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, Tooltip, Title } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import { Chart } from 'react-chartjs-2';
import axios from "axios";

ChartJS.register(CategoryScale, LinearScale, BoxPlotController, BoxAndWiskers, Tooltip, Title);

const apiUrl = process.env.REACT_APP_API_URL;

const groupProducts = (products) => {
    const groupedData = {};

    products.forEach(product => {
        const state = product.state[0];
        const vat = product.vat_invoice ? 'z VAT' : 'bez VAT';
        const key = `${state} (${vat})`;

        if (!groupedData[key]) {
            groupedData[key] = [];
        }
        groupedData[key].push(parseFloat(product.price));
    });

    return groupedData;
};

const ProductBoxPlot = ({ hu }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${apiUrl}/products/${hu}/allegro`);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [hu]);

    const groupedData = groupProducts(products);
    console.log(groupedData)

    return (
        <div>
            <h2>Wykresy pudełkowe dla różnych grup produktów</h2>
            <div className="charts-container">
                {Object.entries(groupedData).map(([group, prices]) => {
                    prices.sort((a, b) => a - b);
                    const chartData = {
                        labels: [group],
                        datasets: [
                            {
                                label: `Rozkład cen dla: ${group}`,
                                data: [
                                    {
                                        min: Math.min(...prices),
                                        q1: prices[Math.floor(prices.length * 0.25)],
                                        median: prices[Math.floor(prices.length * 0.5)],
                                        q3: prices[Math.floor(prices.length * 0.75)],
                                        max: Math.max(...prices),
                                    },
                                ],
                                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                                borderColor: 'rgba(75, 192, 192, 1)',
                                borderWidth: 1,
                            },
                        ],
                    };

                    const options = {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                            },
                        },
                    };

                    return (
                        <div key={group} className="chart-item">
                            <Chart type="boxplot" data={chartData} options={options} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProductBoxPlot;
